
export const projectFour = {
  id: 'Project',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Project 4',
  headline: 'Scout',
  description:
    "Developed and deployed diverse web applications for Pacer using React, Typescript, and Firebase, including a fully centralized live auction browser enabling users to buy and view vehicles. Integrated features such as profit margin calculations, bid costs, and shipping expenses, leveraging web scraping to aggregate data from multiple marketplaces.",
  buttonLabel: 'React Native' ,
  buttonLabel2: "Website",
  imgStart: false,
  img: require('../../images/Scout.mp4'),
  alt: 'Papers',
  dark: true,
  primary: true,
  darkText: false
};